import { COUNTRY_NAMES, LANGUAGES } from '@resparke/staticData';
import { WizardGroupCollection } from '@resparke/modules';
import { FormControls } from '@resparke/models';

export const languageCountryGroup: WizardGroupCollection = {
  'language-and-country': {
    persistenceId: 'languageCountry',
    groupName: 'Language & Country',
    steps: [
      {
        stepName: 'language',
        sidebar: {
          content: 'wiz-lac–language-helper:content',
        },
        form: {
          showProgress: true,
          content: {
            title: 'wiz-lac–language:title',
          },
          fields: [
            {
              name: 'primaryLanguage',
              label: 'Primary language',
              type: FormControls.AUTOCOMPLETE,
              options: LANGUAGES.map(val => ({ viewValue: val.languageName, value: val.languageCode})),
              placeholder: 'Select language',
              required: true,
              defaultValue: 'EN',
            },
            {
              name: 'secondaryLanguage',
              label: 'Secondary language',
              type: FormControls.AUTOCOMPLETE,
              options: LANGUAGES.map(val => ({ viewValue: val.languageName, value: val.languageCode})),
              placeholder: 'Select language',
            },
          ],
          actions: {
            next: '/language-and-country/country'
          }
        }
      },
      {
        stepName: 'country',
        sidebar: {
          content: 'wiz-lac–country-helper:content',
        },
        form: {
          showProgress: true,
          content: {
            title: 'wiz-lac–country:title',
          },
          fields: [
            {
              name: 'countryOfBirth',
              label: 'Country of birth?',
              type: FormControls.AUTOCOMPLETE,
              options: COUNTRY_NAMES.map(val => ({ viewValue: val.label, value: val.code})),
              placeholder: 'Select country',
              required: true,
            },
            {
              name: 'countryGrewUp',
              label: 'Where did they grow up?',
              placeholder: 'Select country',
              type: FormControls.AUTOCOMPLETE,
              options: COUNTRY_NAMES.map(val => ({ viewValue: val.label, value: val.code})),
              required: true,
            },
            {
              name: 'countryOtherPlaces',
              label: 'Other places they have lived',
              placeholder: 'Are there any places or regions in particular to them that are important over the course of their life?',
              type: FormControls.TEXTAREA,
              rows: 6,
              showCount: true,
              maxLength: 500,
            }
          ],
          actions: {
            previous: '/language-and-country/language',
            save: {
              label: 'Save'
            }
          }
        }
      }
    ]
  }
};