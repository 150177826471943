import { FormControls } from '@resparke/models';
import { WizardGroupCollection } from '@resparke/modules';

export const peopleAndPetsGroup: WizardGroupCollection = {
  'people-and-pets': {
    persistenceId: 'peoplePets',
    groupName: 'People and Pets',
    steps: [
      {
        stepName: 'people',
        sidebar: {
          content: 'wiz-pap–people-helper:content',
        },
        form: {
          showProgress: true,
          content: {
            title: 'wiz-pap–people:title',
          },
          fields: [
            {
              name: 'importantPeople',
              label: 'Add one or more important people',
              type: FormControls.GRID_ADD,
              textAddIcon: 'add_circle',
              textItemIcon: 'diversity_1',
              columns: [{
                name: 'name',
                type: FormControls.TEXT,
                placeholder: `Person's name`,
              }, {
                name: 'relationship',
                type: FormControls.SELECT,
                placeholder: `What is their relationship`,
                query: {
                  operation: 'getStaticData',
                  name: 'familyRelationship',
                  params: {
                    items: ['staticFamilyRelationship']
                  }
                },
              }]
            },
          ],
          actions: {
            skip: true,
            next: '/people-and-pets/pets'
          }
        }
      },
      {
        stepName: 'pets',
        sidebar: {
          content: 'wiz-pap–pets-helper:content',
        },
        form: {
          showProgress: true,
          content: {
            title: 'wiz-pap–pets:title',
          },
          fields: [
            {
              name: 'pets',
              label: 'Did they have any beloved pets or a special connection with animals?',
              placeholder: 'Add pet’s name & animal type (e.g. Mitsy the black cat)',
              type: FormControls.TEXTAREA_ADD,
              rows: 2,
              showCount: true,
              maxLength: 100,
              textAddIcon: 'add_circle',
              textItemIcon: 'pets',
              defaultValue: [],
            },
            {
              name: 'animals',
              label: 'Which types of animals are they most fond of?',
              type: FormControls.CHIPS,
              multiple: true,
              query: {
                operation: 'getCategories',
                name: 'animals',
                params: {
                  slug: '/pets-and-animals',
                  type: 'VIDEO',
                },
              },
            },
            {
              name: 'petsMemories',
              placeholder: 'Do they remember any specific memories about their pets?',
              type: FormControls.TEXTAREA,
              rows: 6,
              showCount: true,
              maxLength: 500,
            }
          ],
          actions: {
            previous: '/people-and-pets/people',
            save: {
              label: 'Save'
            }
          }
        }
      },
    ]
  }
};