import { FormControls } from '@resparke/models';
import { WizardGroupCollection } from '@resparke/modules';

export const occupationsLifeAchievementsGroup: WizardGroupCollection = {
  'occupations-and-life-achievements': {
    persistenceId: 'occupationsLife',
    groupName: 'Occupations & Life Achievements',
    steps: [
      {
        stepName: 'occupation',
        sidebar: {
          content: 'wiz-ola–occupation-helper:content',
        },
        form: {
          showProgress: true,
          content: {
            title: 'wiz-ola–occupation:title',
          },
          fields: [
            {
              name: 'industries',
              label: 'What industry(s) did they work in and what was their occupation?',
              type: FormControls.CHIPS,
              multiple: true,
              query: {
                operation: 'getCategories',
                name: 'industries',
                params: {
                  slug: '/occupations',
                  type: 'VIDEO',
                },
              },
            },
            {
              name: 'suggestedOccupations',
              type: FormControls.CHIP_INPUT,
              placeholder: 'Add occupation',
            },
            {
              name: 'memories',
              placeholder: 'Do they have any special work memories?',
              type: FormControls.TEXTAREA,
              rows: 6,
              showCount: true,
              maxLength: 500,
            }
          ],
          actions: {
            skip: true,
            next: '/occupations-and-life-achievements/life-achievements'
          }
        }
      },
      {
        stepName: 'life-achievements',
        sidebar: {
          content: 'wiz-ola–achievements-helper:content',
        },
        form: {
          showProgress: true,
          content: {
            title: 'wiz-ola–achievements:title',
          },
          fields: [
            {
              name: 'achievements',
              placeholder: 'Add new achievement',
              type: FormControls.TEXTAREA_ADD,
              rows: 3,
              showCount: true,
              maxLength: 300,
              textAddIcon: 'add_circle',
              textItemIcon: 'star_rate',
              defaultValue: [],
            }
          ],
          actions: {
            previous: '/occupations-and-life-achievements/occupation',
            save: {
              label: 'Save'
            }
          }
        }
      },
    ]
  }
};