import { FormControls } from '@resparke/models';
import { WizardGroupCollection } from '@resparke/modules';

export const musicGroup: WizardGroupCollection = {
  'music': {
    persistenceId: 'music',
    groupName: 'Music',
    steps: [
      {
        stepName: 'genre',
        sidebar: {
          content: 'wiz-mus–genre-helper:content',
        },
        form: {
          showProgress: true,
          content: {
            title: 'wiz-mus–genre:title',
          },
          fields: [
            {
              name: 'genres',
              label: 'What are their favourite music genres? Select at least one genre.',
              type: FormControls.CHIPS,
              multiple: true,
              query: {
                operation: 'getCategories',
                name: 'musicGenres',
                params: {
                  slug: '/music-categories',
                  type: 'MUSIC',
                },
              },
            },
          ],
          actions: {
            skip: true,
            next: '/music/musicians-and-memories'
          }
        }
      },
      {
        stepName: 'musicians-and-memories',
        sidebar: {
          content: 'wiz-mus–artist-helper:content',
        },
        form: {
          showProgress: true,
          content: {
            title: 'wiz-mus–artist:title',
          },
          fields: [
            {
              name: 'favouriteArtists',
              label: 'Who are their favourite musicians or artists?',
              type: FormControls.AUTOCOMPLETE_CHIP,
              icon: 'expand_more',
              multiple: true,
              query: {
                operation: 'getArtists',
                name: 'musicArtists',
              },
            },
            {
              name: 'suggestedArtists',
              type: FormControls.CHIP_INPUT,
              placeholder: 'Add musician or artist"',
            },
            {
              name: 'memories',
              placeholder: 'Do they have any specific music memories or favourite songs? What music do they remember from their childhood?',
              type: FormControls.TEXTAREA,
              rows: 6,
              showCount: true,
              maxLength: 500,
            }
          ],
          actions: {
            previous: '/music/genre',
            save: {
              label: 'Save'
            }
          }
        }
      },
    ]
  }
};