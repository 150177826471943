import { RELIGIONS } from '@resparke/staticData';
import { FormControls } from '@resparke/models';
import { WizardGroupCollection } from '@resparke/modules';

export const cultureValuesBeliefsGroup: WizardGroupCollection = {
  'culture-values-and-beliefs': {
    persistenceId: 'cultureValuesBelieves',
    groupName: 'Culture, Values & Beliefs',
    steps: [
      {
        stepName: 'faith-and-spirituality',
        sidebar: {
          content: 'wiz-cvb–faith-helper:content',
        },
        form: {
          showProgress: true,
          content: {
            title: 'wiz-cvb–faith:title',
          },
          fields: [
            {
              name: 'religion',
              label: 'What are their faith or spirituality preferences?',
              type: FormControls.SELECT,
              options: RELIGIONS.map(val => ({ viewValue: val.viewValue, value: val.value})),
              placeholder: 'Select faith or belief',
            },
            {
              name: 'suggestedReligion',
              type: FormControls.CHIP_INPUT,
              placeholder: 'Add faith or belief',
            },
            {
              name: 'practiceReligion',
              type: FormControls.TOGGLE,
              label: 'Is practising their faith important to them?',
              defaultValue: false,
              toggleTextTrue: 'Yes',
              toggleTextFalse: 'No',

            },
          ],
          actions: {
            skip: true,
            next: '/culture-values-and-beliefs/culture'
          }
        }
      },
      {
        stepName: 'culture',
        sidebar: {
          content: 'wiz-cvb–culture-helper:content',
        },
        form: {
          showProgress: true,
          content: {
            title: 'wiz-cvb–culture:title',
          },
          fields: [
            {
              name: 'culturalBackground',
              label: 'Are they of Aboriginal or Torres Strait Islander origin?',
              type: FormControls.CHIPS,
              query: {
                operation: 'getStaticData',
                name: 'culturalBackground',
                params: {
                  items: ['staticCulturalBackground']
                }
              },
            },
          ],
          actions: {
            previous: '/culture-values-and-beliefs/faith-and-spirituality',
            next: '/culture-values-and-beliefs/identitity',
            skip: true,
          }
        }
      },
      {
        stepName: 'identitity',
        sidebar: {
          content: 'wiz-cvb–identity-helper:content',
        },
        form: {
          showProgress: true,
          content: {
            title: 'wiz-cvb–identity:title',
          },
          fields: [
            {
              name: 'personalIdentity',
              type: FormControls.TOGGLE,
              label: 'Do they identify as part of the LGBTQIA+ community?',
              defaultValue: false,
              toggleTextTrue: 'Yes',
              toggleTextFalse: 'No',
            },

          ],
          actions: {
            previous: '/culture-values-and-beliefs/culture',
            next: '/culture-values-and-beliefs/community',
            skip: true,
          }
        }
      },
      {
        stepName: 'community',
        sidebar: {
          content: 'wiz-cvb–ccs-helper:content',
        },
        form: {
          showProgress: true,
          content: {
            title: 'wiz-cvb–ccs:title',
          },
          fields: [
            {
              name: 'communityGroups',
              label: 'Were they actively involved in any of the following community clubs or engaged in service activities with them?',
              type: FormControls.CHIPS,
              query: {
                operation: 'getStaticData',
                name: 'communityGroups',
                params: {
                  items: ['staticCommunityGroups']
                }
              },
              multiple: true,
            },
          ],
          actions: {
            previous: '/culture-values-and-beliefs/identitity',
            save: {
              label: 'Save'
            }
          }
        }
      }
    ]
  }
};