import { FormControls } from '@resparke/models';
import { WizardGroupCollection } from '@resparke/modules';


export const orientationGroup: WizardGroupCollection = {
  orientation: {
    persistenceId: 'orientation',
    groupName: 'orientation',
    steps: [
      {
        stepName: 'welcome',
        sidebar: {
          content: 'wiz-ori-welcome-helper:content',
        },
        form: {
          content: {
            preTitle: 'wiz-ori-welcome:pretitle',
            title: 'wiz-ori-welcome:title',
            introduction: 'wiz-ori-welcome:introduction',
          },
          data: {
            getStartedUrl: '/orientation/relationship'
          }
        }
      },
      {
        stepName: 'relationship',
        sidebar: {
          content: 'wiz-ori-welcome-helper:content',
        },
        form: {
          showProgress: true,
          content: {
            title: 'wiz-ori-relationship:title',
          },
          fields: [
            {
              name: 'relationship',
              type: FormControls.SELECT,
              query: {
                operation: 'getStaticData',
                name: 'familyRelationship',
                params: {
                  items: ['staticFamilyRelationship']
                }
              },
              placeholder: 'Select relationship',
              required: true,
            },
          ],
          data: {
            adjustProgress: -1,
          },
          mutate: 'updateFamily',
          actions: {
            previous: '/orientation/welcome',
            next: '/orientation/confirm'
          }
        },
      },
      {
        stepName: 'confirm',
        sidebar: {
          content: 'wiz-ori-welcome-helper:content',
        },
        form: {
          showProgress: true,
          content: {
            title: 'wiz-ori-confirm:title',
            introduction: 'wiz-ori-confirm:introduction',
          },
          data: {
            adjustProgress: -1,
            prevUrl: '/orientation/relationship',
            nextUrl: '/lifestory'
          }
        },
      }
    ]
  }
};