import { FormControls } from '@resparke/models';
import { WizardGroupCollection } from '@resparke/modules';

export const hobbiesInterestSportsGroup: WizardGroupCollection = {
  'hobbies-interests-and-sports': {
    persistenceId: 'hobbiesInterestsSports',
    groupName: 'Hobbies, Interests & Sports',
    steps: [
      {
        stepName: 'hobbies-and-interest',
        sidebar: {
          content: 'wiz-his–hobbies-helper:content',
        },
        form: {
          showProgress: true,
          content: {
            title: 'wiz-his–hobbies:title',
            introduction: 'wiz-his–hobbies:introduction',
          },
          fields: [
            {
              name: 'hobbiesInterests',
              label: 'What are their Hobbies and special interests?',
              icon: 'expand_more',
              creatable: true,
              type: FormControls.AUTOCOMPLETE_CHIP,
              query: {
                operation: 'getStaticData',
                name: 'interestsHobbies',
                params: {
                  items: ['staticInterestsHobbies']
                }
              },
              placeholder: 'Select or add interest & hobby',
            },
            {
              name: 'hobbiesMemories',
              placeholder: 'Do they have any specific memories about their hobbies or interests?',
              type: FormControls.TEXTAREA,
              rows: 6,
              showCount: true,
              maxLength: 500,
            }
          ],
          actions: {
            skip: true,
            next: '/hobbies-interests-and-sports/sports'
          }
        }
      },
      {
        stepName: 'sports',
        sidebar: {
          content: 'wiz-his–sports-helper:content',
        },
        form: {
          showProgress: true,
          content: {
            title: 'wiz-his–sports:title',
          },
          fields: [
            {
              name: 'sports',
              label: 'What types of sports do they like to watch or play?',
              type: FormControls.CHIPS,
              query: {
                operation: 'getCategories',
                name: 'sports',
                params: {
                  slug: '/sport',
                  type: 'VIDEO',
                }
              },
              multiple: true,
            },
            {
              name: 'suggestedSports',
              type: FormControls.CHIP_INPUT,
              placeholder: 'Add sport',
            },
            {
              name: 'sportsMemories',
              placeholder: 'Special sporting memories or favourite sporting teams?',
              type: FormControls.TEXTAREA,
              rows: 6,
              showCount: true,
              maxLength: 500,

            }
          ],
          actions: {
            previous: '/hobbies-interests-and-sports/hobbies-and-interest',
            save: {
              label: 'Save'
            }
          }
        }
      },

    ]
  }
};