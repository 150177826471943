import { FormControls } from '@resparke/models';
import { WizardGroupCollection } from '@resparke/modules';

export const conversationStartersGroup: WizardGroupCollection = {
  'conversation-starters': {
    persistenceId: 'conversationStarters',
    groupName: 'Conversation Starters',
    steps: [
      {
        stepName: 'stories',
        sidebar: {
          content: 'wiz-con–starters-helper:content',
        },
        form: {
          showProgress: true,
          content: {
            title: 'wiz-con–starters:title',
          },
          fields: [
            {
              name: 'conversationStarters',
              placeholder: 'You can add multipe conversation starters (e.g. Dad loves to talk about his time at University)',
              type: FormControls.TEXTAREA_ADD,
              rows: 3,
              showCount: true,
              maxLength: 300,
              textAddIcon: 'add_circle',
              textItemIcon: 'forum',
              defaultValue: [],
            }
          ],
          actions: {
            save: {
              label: 'Save'
            }
          }
        }
      },
    ]
  }
};