import { WizardGroupCollection } from '@resparke/modules';
import { conversationStartersGroup } from './conversation-starters';
import { cultureValuesBeliefsGroup } from './culture-values-beliefs';
import { hobbiesInterestSportsGroup } from './hobbies-interest-sports';
import { languageCountryGroup } from './language-country.group';
import { musicGroup } from './music.group';
import { occupationsLifeAchievementsGroup } from './occupations-life-achievements';
import { orientationGroup } from './orientation.group';
import { peopleAndPetsGroup } from './people-pets';
import { photosGroup } from './photos';

export const groups: WizardGroupCollection = {
  ...orientationGroup,
  ...languageCountryGroup,
  ...cultureValuesBeliefsGroup,
  ...hobbiesInterestSportsGroup,
  ...occupationsLifeAchievementsGroup,
  ...peopleAndPetsGroup,
  ...musicGroup,
  ...conversationStartersGroup,
  ...photosGroup,
}
